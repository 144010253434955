<nav class="navbar navbar-expand-lg fixed-top nbCustom"
[class.scrolled]="isScrolled" [class.initial]="!isScrolled">
  <div class="container container_fluid px-0">
    <div class="d-flex">
      <a class="navbar-brand" href="work/external/landing">
        <img src="{{ urlFront }}/assets/apps/images/logo/logo-blue.svg"
        alt="Adison Jimenez" class="img-fluid" width="115" height="20" />
        <!--<span>{{ appTitle }}</span>-->
      </a>
      <!--<div class="dropdown d-none d-md-block">
        <button type="button" id="menuMain"
        class="btn btn-light-primary text-primary"
        data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fe fe-list me-2 align-middle"></i>
          <span>Contribucion</span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="menuMain">
          <li>
            <a href="work/external/landing"
            class="dropdown-item">
              <span>Pagina Principal</span>
            </a>
          </li>
          <li>
            <a href="https://www.paypal.com/donate/?hosted_button_id=ACV7LKW4SWRNJ"
            class="dropdown-item">
              <span>Donacion</span>
            </a>
          </li>
          <li class="d-none">
            <a href="work/external/login"
            class="dropdown-item">
              <span>Iniciar Sesion</span>
            </a>
          </li>
          <li class="d-none">
            <a href="work/external/register"
            class="dropdown-item">
              <span>Regitrarme</span>
            </a>
          </li>
        </ul>
      </div>-->
    </div>
    <div class="order-lg-3">
      <div class="d-flex align-items-center">
        <div class="dropdown d-none">
          <button type="button" id="themeData" name="themeData"
          class="btn btn-light btn-icon rounded-circle d-flex align-items-center"
          aria-expanded="false" data-bs-toggle="dropdown"
          aria-label="Toggle theme (auto)">
            <i class="bi theme-icon-active"></i>
            <span class="visually-hidden bs-theme-text">Toggle theme</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="bs-theme-text">
            <li>
              <button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="light" aria-pressed="false">
                <i class="bi theme-icon bi-sun-fill"></i>
                <span class="ms-2 d-none">Light</span>
                <span class="ms-2">Claro</span>
              </button>
            </li>
            <li>
              <button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="dark" aria-pressed="false">
                <i class="bi theme-icon bi-moon-stars-fill"></i>
                <span class="ms-2 d-none">Dark</span>
                <span class="ms-2">Oscuro</span>
              </button>
            </li>
            <li>
              <button type="button" class="dropdown-item d-flex align-items-center active" data-bs-theme-value="auto" aria-pressed="true">
                <i class="bi theme-icon bi-circle-half"></i>
                <span class="ms-2 d-none">Auto</span>
                <span class="ms-2">Automatico</span>
              </button>
            </li>
          </ul>
        </div>
        <!--<a href="#" class="btn btn-icon btn-light rounded-circle d-md-inline-flex ms-2">
          <i class="fe fe-shopping-cart align-middle"></i>
        </a>-->
        <!--<a href="work/external/login"
        class="btn btn-primary ms-2 d-none d-lg-block">
          <span>Iniciar Sesion</span>
        </a>-->
        <!--<a href="work/external/register"
        class="btn btn-primary ms-2 d-none d-lg-block">
          <span>Registrarme</span>
        </a>-->
        <!-- Button -->
        <button
          class="navbar-toggler collapsed ms-2 ms-lg-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-default"
          aria-controls="navbar-default"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="icon-bar top-bar mt-0"></span>
          <span class="icon-bar middle-bar"></span>
          <span class="icon-bar bottom-bar"></span>
        </button>
      </div>
    </div>

    <!-- Collapse -->
    <div class="collapse navbar-collapse" id="navbar-default">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item">
          <a class="nav-link" href="work/external/landing">
            <span>Inicio</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="work/ground/landing">
            <span>Blog</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="{{ domainMajor }}" target="_blank">
            <span>Pagina Principal</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
