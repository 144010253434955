import { Component } from '@angular/core';

@Component({
  selector: 'app-ge-in-footer',
  templateUrl: './ge-in-footer.component.html',
  styleUrls: ['./ge-in-footer.component.css']
})
export class GeInFooterComponent {

}
