import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { Environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/funcs/base.service';

@Component({
  selector: 'app-ge-gr-navbar',
  templateUrl: './ge-gr-navbar.component.html',
  styleUrls: ['./ge-gr-navbar.component.css']
})
export class GeGrNavbarComponent implements OnInit {
  domainMajor: string = '';
  appTitle: string = Environment.title;
  urlFront: string = '';
  isScrolled = false;
  darkTheme = true;

  constructor (
    private renderer: Renderer2,
    private router: Router,
    private serviceBase: BaseService,
  ) {}

  ngOnInit(): void {
    this.getUrlHref();
    this.getDomain();
  }

  getUrlHref() {
    let urlCurr = this.serviceBase.getCurrentUrl();
    let urlBase = this.serviceBase.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlCurr + urlBase;
  }

  getDomain() {
    let dmMajor = this.serviceBase.getDomainMajor();
    this.domainMajor = dmMajor;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isScrolled = scrollPosition > 0;

    const htmlElement = document.querySelector('html');

    if (htmlElement) {
      if (this.isScrolled) {
        this.renderer.addClass(document.body, 'scrolled');
        if (htmlElement.getAttribute('data-bs-theme') === 'dark') {
          this.renderer.addClass(document.body, 'dark-theme');
        }
      } else {
        this.renderer.removeClass(document.body, 'scrolled');
        this.renderer.removeClass(document.body, 'dark-theme');
      }
    }
  }
}
