import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/funcs/base.service';

@Component({
  selector: 'app-ge-gr-footer',
  templateUrl: './ge-gr-footer.component.html',
  styleUrls: ['./ge-gr-footer.component.css']
})
export class GeGrFooterComponent implements OnInit {
  domainMajor: string = '';
  appTitle: string = Environment.title;
  urlFront: string = '';

  constructor (
    private router: Router,
    private serviceBase: BaseService,
  ) {}

  ngOnInit(): void {
    this.getUrlHref();
    this.getDomain();
  }

  getUrlHref() {
    let urlCurr = this.serviceBase.getCurrentUrl();
    let urlBase = this.serviceBase.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlCurr + urlBase;
  }

  getDomain() {
    let dmMajor = this.serviceBase.getDomainMajor();
    this.domainMajor = dmMajor;
  }
}
