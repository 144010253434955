import { Component } from '@angular/core';

@Component({
  selector: 'app-ge-in-navbar',
  templateUrl: './ge-in-navbar.component.html',
  styleUrls: ['./ge-in-navbar.component.css']
})
export class GeInNavbarComponent {

}
