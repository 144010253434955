<app-ge-gr-profile></app-ge-gr-profile>
<main>
  <!-- section -->
  <section class="position-relative bnMdLanding">
    <div class="fixedElement">
      <img src="{{ urlFront }}/assets/apps/images/ground/landing/closeup-developer.jpg"
      alt="Background Image" class="img-fluid bnMdBackImage" />
      <div class="container my-lg-8 position-relative bnMdContainer nMdBackContent">
        <div class="row align-items-center">
          <div class="col-12 mb-8"></div>
          <div class="col-lg-10 offset-lg-2 mb-6 mb-lg-0">
            <div class="text-center">
              <h5 class="display-5 fw-bold mb-3 text-white">
                <span>
                  Actividades, Modelos y Metodologias del Desarrollo de Software
                </span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<main class="docs-wrapper">
  <section class="container-fluid">
    <div class="row">
      <div class="col-12 mb-4"></div>
      <div class="offset-xxl-1 offset-xl-1 col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
        <div class="docs_content mx-xxl-9">
          <!-- Card Informacion -->
          <div class="card rounded-0 smooth-shadow-md bg-white">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center mb-0 mt-0">
                <div class="d-flex align-items-center">
                  <img src="{{ urlFront }}/assets/apps/images/ground/profile/adison_jimenez.png"
                  alt="avatar" class="rounded-circle avatar-md">
                  <div class="ms-2 lh-1">
                    <h5 class="mb-1">
                      <span>Adison Jimenez</span>
                    </h5>
                    <span class="text-info">
                      Estudiante Universitario
                    </span>
                  </div>
                </div>
                <div>
                  <div class="ms-2 lh-1">
                    <h5 class="mb-1">
                      <span>Fecha Publicacion</span>
                    </h5>
                    <span class="text-info mb-1">
                      Marzo 01, 2024
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="mt-4 mb-4">

          <div class="row">
            <!-- Card Informacion -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <div class="card-body">
                  <div class="tab-content" id="tabActivity">
                    <div class="tab-pane fade active show" id="titleActivity" role="tabpanel" aria-labelledby="purpose-tab">
                      <h3 class="mb-2">
                        <span>Actividades del Desarrollo de Software</span>
                      </h3>
                      <ul class="list-unstyled">
                        <li class="d-flex mb-2">
                          <span class="me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-circle text-success" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                            </svg>
                          </span>
                          <span>
                            El desarrollo de software involucra una serie
                            de actividades estructuradas que se organizan
                            típicamente en un proceso conocido como
                            el ciclo de vida del software.
                            A partir del texto proporcionado, podemos detallar
                            las actividades principales que componen este ciclo,
                            agrupándolas en las etapas de planeación, modelado,
                            construcción, y despliegue, y añadiendo detalles
                            sobre actividades transversales como la
                            especificación de requerimientos e implementación.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla de datos -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- card header  -->
                <div class="card-header">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Especificación de Requerimientos
                      </code>
                    </span>
                  </h4>
                  <p class="mb-0">
                    La especificación de requerimientos es el proceso de definir
                    lo que el sistema debería hacer, identificando las necesidades
                    de los usuarios finales y los objetivos del software.
                    Esta etapa es fundamental para el éxito del proyecto de software,
                    ya que establece las bases y el alcance del mismo.
                    Las actividades principales incluyen:
                  </p>
                </div>
                <!-- table  -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm text-nowrap mb-0 table-centered">
                      <caption class="d-none">Contenido de la tabla informativa</caption>
                      <thead class="table-light">
                        <tr>
                          <th>Claves</th>
                          <th>Contenido</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Recolección de Requerimientos
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Interactuar con los usuarios finales, stakeholders, y otros para recoger todas las necesidades y restricciones del software.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Análisis de Requerimientos
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Clarificar y priorizar las necesidades para asegurar que sean comprensibles y realizables.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Documentación de Requerimientos
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Crear documentos detallados que describan los requerimientos del software de manera clara y precisa.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla de datos -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- card header  -->
                <div class="card-header">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Planeación
                      </code>
                    </span>
                  </h4>
                  <p class="mb-0">
                    La planeación implica definir los recursos,
                    tiempos y actividades necesarias para
                    completar el proyecto de software. Incluye:
                  </p>
                </div>
                <!-- table  -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm text-nowrap mb-0 table-centered">
                      <caption class="d-none">Contenido de la tabla informativa</caption>
                      <thead class="table-light">
                        <tr>
                          <th>Claves</th>
                          <th>Contenido</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Estimación de recursos y costos
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Determinar los recursos (humanos, tecnológicos, financieros) necesarios, así como los costos asociados.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Planificación del tiempo
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Establecer un cronograma para las distintas fases y actividades del proyecto.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Asignación de roles
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Definir quién hará qué, distribuyendo responsabilidades entre el equipo de desarrollo.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla de datos -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- card header  -->
                <div class="card-header">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Modelado
                      </code>
                    </span>
                  </h4>
                  <p class="mb-0">
                    El modelado se refiere a la creación de modelos
                    abstractos del sistema que se va a construir.
                    Estos modelos ayudan a visualizar y prever
                    la estructura y comportamiento del software.
                    Las actividades incluyen:
                  </p>
                </div>
                <!-- table  -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm text-nowrap mb-0 table-centered">
                      <caption class="d-none">Contenido de la tabla informativa</caption>
                      <thead class="table-light">
                        <tr>
                          <th>Claves</th>
                          <th>Contenido</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Diseño de la Arquitectura
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Definir cómo se estructurará el sistema en términos de componentes de software y su interacción.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Modelado de datos
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Diseñar la estructura de datos que el software utilizará y gestionará.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Diseño de Interfaz
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Crear el diseño de las interfaces de usuario, asegurando usabilidad y accesibilidad.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla de datos -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- card header  -->
                <div class="card-header">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Desarrollo
                      </code>
                    </span>
                  </h4>
                  <p class="mb-0">
                    El desarrollo, también conocido como construcción,
                    es la fase donde el software se escribe realmente,
                    codificando los modelos y diseños previamente establecidos. Incluye:
                  </p>
                </div>
                <!-- table  -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm text-nowrap mb-0 table-centered">
                      <caption class="d-none">Contenido de la tabla informativa</caption>
                      <thead class="table-light">
                        <tr>
                          <th>Claves</th>
                          <th>Contenido</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Codificación
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Escribir el código fuente utilizando lenguajes de programación adecuados.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Revisión de código
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Verificar la calidad y corrección del código mediante revisiones por pares o herramientas automatizadas.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla de datos -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- card header  -->
                <div class="card-header">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Implementación
                      </code>
                    </span>
                  </h4>
                  <p class="mb-0">
                    La implementación abarca todas las actividades
                    necesarias para desplegar el software en su
                    entorno de operación y hacerlo accesible
                    a los usuarios finales. Esto incluye:
                  </p>
                </div>
                <!-- table  -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm text-nowrap mb-0 table-centered">
                      <caption class="d-none">Contenido de la tabla informativa</caption>
                      <thead class="table-light">
                        <tr>
                          <th>Claves</th>
                          <th>Contenido</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Pruebas
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Realizar pruebas sistemáticas para asegurar que el software cumple con los requerimientos especificados y está libre de defectos.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Despliegue
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Configurar y habilitar el software en su entorno de producción.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Capacitación de usuarios
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Proveer la formación necesaria a los usuarios finales para el correcto uso del software.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="mt-2 mb-4">

          <div class="row">
            <!-- Card Informacion -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <div class="card-body">
                  <div class="tab-content" id="tabModel">
                    <div class="tab-pane fade active show" id="titleModel" role="tabpanel" aria-labelledby="model-tab">
                      <h3 class="mb-2">
                        <span>Modelos de Desarrollo de Software</span>
                      </h3>
                      <ul class="list-unstyled">
                        <li class="d-flex mb-2">
                          <span class="me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-circle text-success" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                            </svg>
                          </span>
                          <span>
                            Los modelos de desarrollo de software son frameworks
                            metodológicos usados para estructurar, planificar y
                            controlar el proceso de desarrollo de sistemas de información.
                            A lo largo de los años, se han propuesto varios modelos,
                            cada uno con sus propias características, ventajas y desventajas.
                            A continuación, se describen algunos de los modelos más conocidos:
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla de datos -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- card header  -->
                <div class="card-header">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Modelo en Cascada
                      </code>
                    </span>
                  </h4>
                  <p class="mb-0">
                    El modelo en cascada es uno de los enfoques más tradicionales y lineales para el desarrollo de software.
                  </p>
                </div>
                <!-- table  -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm text-nowrap mb-0 table-centered">
                      <caption class="d-none">Contenido de la tabla informativa</caption>
                      <thead class="table-light">
                        <tr>
                          <th>Contenido</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Se caracteriza por una serie de fases que fluyen hacia abajo como una cascada, donde cada fase comienza solo después de que la anterior ha concluido.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Las fases típicas incluyen análisis de requisitos, diseño del sistema, implementación, pruebas, despliegue y mantenimiento.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Este modelo es simple y fácil de entender, pero es rígido y no maneja bien los cambios en los requisitos.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla de datos -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- card header  -->
                <div class="card-header">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Modelos Incrementales
                      </code>
                    </span>
                  </h4>
                  <p class="mb-0">
                    Los modelos incrementales combinan elementos del modelo en cascada aplicados de manera repetitiva en pequeñas secciones del proyecto, llamadas incrementos.
                  </p>
                </div>
                <!-- table  -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm text-nowrap mb-0 table-centered">
                      <caption class="d-none">Contenido de la tabla informativa</caption>
                      <thead class="table-light">
                        <tr>
                          <th>Contenido</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Cada incremento pasa por sus propias fases de análisis, diseño, implementación y pruebas, y añade funcionalidades adicionales al software hasta que el producto final está completo.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Este modelo permite la entrega de versiones funcionales del software al cliente de manera temprana en el proyecto y facilita el manejo de cambios en los requisitos.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla de datos -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- card header  -->
                <div class="card-header">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Modelo de Evolución por Prototipos
                      </code>
                    </span>
                  </h4>
                  <p class="mb-0">
                    El modelo de evolución por prototipos se centra en la creación de prototipos trabajables en las primeras etapas del desarrollo de software.
                  </p>
                </div>
                <!-- table  -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm text-nowrap mb-0 table-centered">
                      <caption class="d-none">Contenido de la tabla informativa</caption>
                      <thead class="table-light">
                        <tr>
                          <th>Contenido</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Estos prototipos no son versiones finales, sino modelos preliminares que permiten a los usuarios finales y a los desarrolladores explorar cómo deberían funcionar las características y hacer ajustes antes del desarrollo final.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Este enfoque es particularmente útil para proyectos donde los requisitos no están claros desde el inicio.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Al probar los prototipos, se pueden hacer modificaciones iterativas hasta que el producto cumpla con las necesidades del usuario.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla de datos -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- card header  -->
                <div class="card-header">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Modelos Ágiles
                      </code>
                    </span>
                  </h4>
                  <p class="mb-0">
                    Los modelos ágiles son un conjunto de métodos de desarrollo de software basados en principios de desarrollo iterativo e incremental.
                  </p>
                </div>
                <!-- table  -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm text-nowrap mb-0 table-centered">
                      <caption class="d-none">Contenido de la tabla informativa</caption>
                      <thead class="table-light">
                        <tr>
                          <th>Contenido</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-wrap">
                            <span class="text-dark">
                              En lugar de planificar y ejecutar el proyecto en su totalidad desde el principio, los métodos ágiles dividen el trabajo en pequeñas partes que se completan en ciclos de trabajo cortos llamados iteraciones o sprints.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Los modelos ágiles enfatizan la colaboración constante con el cliente, la adaptabilidad y la entrega temprana de partes funcionales del software.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Scrum y Kanban son ejemplos de metodologías ágiles que buscan mejorar la respuesta a los cambios en los requisitos y aumentar la eficiencia del desarrollo.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="mt-2 mb-4">

          <div class="row">
            <!-- Card Informacion -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <div class="card-body">
                  <div class="tab-content" id="tabMethodology">
                    <div class="tab-pane fade active show" id="titleMethodology" role="tabpanel" aria-labelledby="methodology-tab">
                      <h3 class="mb-2">
                        <span>Uso de Metodologías Agiles</span>
                      </h3>
                      <ul class="list-unstyled">
                        <li class="d-flex mb-2">
                          <span class="me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-circle text-success" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                            </svg>
                          </span>
                          <span>
                            La guía orientadora propone el uso de metodologías ágiles en la etapa
                            de profundización, destacando cómo estos modelos, en particular SCRUM,
                            integran elementos de los modelos en cascada, incremental y de prototipos,
                            pero con un enfoque distintivo que se adapta bien a los proyectos de desarrollo
                            de software donde los requerimientos son propensos a cambiar a lo largo del tiempo.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla de datos -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- card header  -->
                <div class="card-header">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Integración de Estrategias de Desarrollo
                      </code>
                    </span>
                  </h4>
                  <p class="mb-0">
                    SCRUM, como se menciona, se basa principalmente en el modelo incremental,
                    permitiendo el desarrollo del software en partes o incrementos.
                    A su vez, incorpora la flexibilidad del modelo de prototipos
                    al permitir iteraciones rápidas sobre estas partes, lo cual
                    facilita la adaptación a cambios y la mejora continua del producto.
                    Aunque no se menciona explícitamente, también toma prestado del modelo
                    en cascada en cuanto a tener etapas definidas
                    (como planificación, revisión y retrospectivas),
                    pero aplicadas de manera flexible y repetitiva
                    en cada sprint o ciclo de desarrollo.
                  </p>
                </div>
              </div>
            </div>
            <!-- Tabla de datos -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- card header  -->
                <div class="card-header">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Ventajas de las Metodologías Ágiles
                      </code>
                    </span>
                  </h4>
                </div>
                <!-- table  -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm text-nowrap mb-0 table-centered">
                      <caption class="d-none">Contenido de la tabla informativa</caption>
                      <thead class="table-light">
                        <tr>
                          <th>Claves</th>
                          <th>Contenido</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Adaptabilidad a Cambios
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Una de las principales fortalezas de las metodologías ágiles, y en particular de SCRUM,
                              es su capacidad para manejar cambios en los requisitos de manera efectiva.
                              Esto se logra a través de ciclos cortos de desarrollo (sprints),
                              que permiten incorporar retroalimentación y ajustes de manera continua.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Menor Formalización
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Al hacer más "liviana" la formalización de las etapas de desarrollo,
                              las metodologías ágiles aceleran el proceso de producción de software y reducen la carga
                              de trabajo asociada con la documentación y otros procesos formales extensos.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Equipos Cohesionados
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              La enfatización en equipos pequeños, autoorganizados y
                              multidisciplinarios fomenta una mayor comunicación,
                              colaboración y compromiso entre los miembros del equipo,
                              lo que se traduce en una mayor eficiencia y satisfacción laboral.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla de datos -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- card header  -->
                <div class="card-header">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Desafíos y Consideraciones
                      </code>
                    </span>
                  </h4>
                  <p class="mb-0">
                    Sin embargo, la implementación exitosa de metodologías ágiles como SCRUM requiere superar ciertos desafíos:
                  </p>
                </div>
                <!-- table  -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm text-nowrap mb-0 table-centered">
                      <caption class="d-none">Contenido de la tabla informativa</caption>
                      <thead class="table-light">
                        <tr>
                          <th>Claves</th>
                          <th>Contenido</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Entrenamiento y Disciplina
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              Para aprovechar al máximo las metodologías ágiles,
                              los equipos de trabajo necesitan una formación adecuada y
                              desarrollar una disciplina en la aplicación consistente
                              de sus principios y prácticas. Esto puede representar un
                              obstáculo para algunas organizaciones, especialmente
                              para aquellas que carecen de los recursos para invertir
                              en entrenamiento o que tienen una cultura organizacional
                              muy arraigada en métodos más tradicionales.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-wrap">
                            <span class="badge bg-info-soft">
                              Accesibilidad
                            </span>
                          </td>
                          <td class="text-wrap">
                            <span class="text-dark">
                              No todas las empresas pueden hacer la transición a metodologías ágiles con facilidad.
                              Las barreras pueden incluir resistencia al cambio, falta de conocimiento especializado
                              y restricciones presupuestarias para la formación y adaptación de los equipos.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="mt-2 mb-4">

          <div class="row">
            <!-- Conclusiones -->
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- Card Body -->
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="position-relative">
                      <img src="{{ urlFront }}/assets/apps/images/ground/profile/adison_jimenez.png" alt="" class="rounded-circle avatar-xl">
                      <a href="#" class="position-absolute mt-2 ms-n3" data-bs-toggle="tooltip" data-placement="top" aria-label="Verifed" data-bs-original-title="Verifed">
                        <img src="{{ urlFront }}/assets/geeks/images/svg/checked-mark.svg" alt="" height="30" width="30">
                      </a>
                    </div>
                    <div class="ms-4">
                      <h4 class="mb-0">
                        <span>Adison Jimenez</span>
                      </h4>
                      <p class="mb-1 fs-6 text-info">
                        Estudiante Universitario
                      </p>
                      <span class="text-warning lh-1 d-flex align-baseline">
                        <span class="fs-6 mx-1 d-none">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                        </span>
                        <span>Conclusiones</span>
                      </span>
                    </div>
                  </div>
                  <hr class="mb-3">
                  <p class="text-dark">
                    El desarrollo de software abarca actividades como la
                    especificación de requerimientos, planeación, modelado,
                    construcción, pruebas, despliegue y mantenimiento,
                    cada una crucial para el éxito del proyecto
                    y la satisfacción del cliente.
                  </p>
                </div>
              </div>
            </div>
            <!-- Conclusiones -->
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- Card Body -->
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="position-relative">
                      <img src="{{ urlFront }}/assets/apps/images/ground/profile/adison_jimenez.png" alt="" class="rounded-circle avatar-xl">
                      <a href="#" class="position-absolute mt-2 ms-n3" data-bs-toggle="tooltip" data-placement="top" aria-label="Verifed" data-bs-original-title="Verifed">
                        <img src="{{ urlFront }}/assets/geeks/images/svg/checked-mark.svg" alt="" height="30" width="30">
                      </a>
                    </div>
                    <div class="ms-4">
                      <h4 class="mb-0">
                        <span>Adison Jimenez</span>
                      </h4>
                      <p class="mb-1 fs-6 text-info">
                        Estudiante Universitario
                      </p>
                      <span class="text-warning lh-1 d-flex align-baseline">
                        <span class="fs-6 mx-1 d-none">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                        </span>
                        <span>Conclusiones</span>
                      </span>
                    </div>
                  </div>
                  <hr class="mb-3">
                  <p class="text-dark">
                    Los modelos como el Cascada, Incremental,
                    Prototipos y Ágiles ofrecen estructuras y
                    enfoques diversos para guiar el proceso
                    de desarrollo, cada uno con ventajas
                    específicas dependiendo de las necesidades
                    y características del proyecto.
                  </p>
                </div>
              </div>
            </div>
            <!-- Conclusiones -->
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <!-- Card Body -->
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="position-relative">
                      <img src="{{ urlFront }}/assets/apps/images/ground/profile/adison_jimenez.png" alt="" class="rounded-circle avatar-xl">
                      <a href="#" class="position-absolute mt-2 ms-n3" data-bs-toggle="tooltip" data-placement="top" aria-label="Verifed" data-bs-original-title="Verifed">
                        <img src="{{ urlFront }}/assets/geeks/images/svg/checked-mark.svg" alt="" height="30" width="30">
                      </a>
                    </div>
                    <div class="ms-4">
                      <h4 class="mb-0">
                        <span>Adison Jimenez</span>
                      </h4>
                      <p class="mb-1 fs-6 text-info">
                        Estudiante Universitario
                      </p>
                      <span class="text-warning lh-1 d-flex align-baseline">
                        <span class="fs-6 mx-1 d-none">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                        </span>
                        <span>Conclusiones</span>
                      </span>
                    </div>
                  </div>
                  <hr class="mb-3">
                  <p class="text-dark">
                    Las metodologías ágiles, como SCRUM, destacan
                    por su adaptabilidad, colaboración y entrega
                    continua, siendo ideales para proyectos donde
                    los requisitos cambian con frecuencia y se
                    requiere flexibilidad y rapidez en la entrega.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr class="mt-2 mb-4">

          <div class="row">
            <div class="col-12 mb-4">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <div class="card-header p-3">
                  <h4 class="mb-1 text-info">
                    <span>
                      <code>
                        Referencias Biblográficas
                      </code>
                    </span>
                  </h4>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12 mb-2">
                      <p class="text-dark mb-1">
                        <code class="text-info">Talently Blog</code>. <code>(2023)</code>.
                        Metodologías de desarrollo de software para ser más eficiente.
                        <code>Recuperado de:</code>
                      </p>
                      <div class="code-toolbar rounded-0">
                        <pre class="language-markup text-info" tabindex="0"><code class="language-markup" data-copy-state="copy">https://talently.tech/blog/metodologias-desarrollo-software-workflow-eficiente/</code></pre>
                        <div class="toolbar">
                          <div class="toolbar-item">
                            <a href="https://talently.tech/blog/metodologias-desarrollo-software-workflow-eficiente/"
                            rel="noopener" target="_blank" class="copy-to-clipboard-button" data-copy-state="copy">
                              <span>Abrir</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-2">
                      <p class="text-dark mb-1">
                        <code class="text-info">Iebschool</code>. <code>(2024)</code>.
                        Las metodologías agiles más utilizadas y sus ventajas
                        <code>Recuperado de:</code>
                      </p>
                      <div class="code-toolbar rounded-0">
                        <pre class="language-markup text-info" tabindex="0"><code class="language-markup" data-copy-state="copy">https://www.iebschool.com/blog/que-son-metodologias-agiles-agile-scrum/</code></pre>
                        <div class="toolbar">
                          <div class="toolbar-item">
                            <a href="https://www.iebschool.com/blog/que-son-metodologias-agiles-agile-scrum/"
                            rel="noopener" target="_blank" class="copy-to-clipboard-button" data-copy-state="copy">
                              <span>Abrir</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Sidebar nav fixed -->
      <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12 d-none d-xl-block position-fixed end-0">
        <div class="sidebar-nav-fixed">
          <span class="px-4 mb-2 d-block text-uppercase ls-md fw-semibold fs-6">
            Contenido
          </span>
          <ul class="list-unstyled">
            <li>
              <a href="work/ground/dsamm#titleActivity" class="text-info active">
                <span>Actividades</span>
              </a>
            </li>
            <li>
              <a href="work/ground/dsamm#titleModel">
                <span>Modelos</span>
              </a>
            </li>
            <li>
              <a href="work/ground/dsamm#titleMethodology">
                <span>Metodologías Agiles</span>
              </a>
            </li>
            <li>
              <a href="work/ground/dsamm#titleConclution">
                <span>Conclusiones</span>
              </a>
            </li>
            <li>
              <a href="work/ground/dsamm#titleReference">
                <span>Referencias Bibliograficas</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <app-ge-gr-footer></app-ge-gr-footer>
</main>
