<footer class="pt-lg-8 pt-5 footer bg-white">
  <div class="container mt-lg-2">
    <div class="row align-items-center g-0 border-top py-2 mt-6">
      <!-- Desc -->
      <!-- justify-content-center -->
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 justify-content-center">
        <div class="d-lg-flex">
          <nav aria-label="" class="nav nav-footer">
            <a class="nav-link ps-0 text-info" href="work/external/landing">
              <img src="{{ urlFront }}/assets/apps/images/logo/logo-blue.svg"
              alt="{{ appTitle }}" class="img-fluid me-1" width="75" height="10" />
              <span>Copyright © 2014 - 2024</span>
              <span class="d-none">Reservados todos los derechos.</span>
            </a>
          </nav>
        </div>
      </div>

      <!-- Links -->
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-md-flex justify-content-end d-none">
        <nav aria-label="" class="nav nav-footer">
          <a class="nav-link ps-0 text-info" href="{{ domainMajor }}" target="_blank">
            <span>Adison Jesus Jimenez Beltran</span>
          </a>
        </nav>
      </div>
    </div>
  </div>
</footer>

<!-- Scroll top -->
<div class="btn-scroll-top">
  <svg class="progress-square svg-content" width="100%" height="100%" viewBox="0 0 40 40">
    <path d="M8 1H32C35.866 1 39 4.13401 39 8V32C39 35.866 35.866 39 32 39H8C4.13401 39 1 35.866 1 32V8C1 4.13401 4.13401 1 8 1Z" />
  </svg>
</div>
