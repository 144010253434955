import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = this.extract();
  }

  private extract(): string {
    const baseTag = document.querySelector('base');
    return baseTag?.getAttribute('href') ?? '/';
  }

  getCurrentUrl(): string {
    const { protocol, host } = window.location;
    //console.log(`${protocol}//${host}`);
    return `${protocol}//${host}`;
  }

  getBaseUrl(): string {
    return this.baseUrl;
  }

  getDomainMajor(): string {
    let protocol = 'https';
    let port = '';
    let sub = '';
    let domain = 'adisonjimenez';
    let ext = 'work';
    let url = this.buildUrl(protocol, port, sub, domain, ext);
    //console.log(`Url Domain Mahjor: ${url}`);
    return url;
  }

  // Construir URL completa basada en parámetros
  buildUrl(
    protocol: string,
    port: string,
    sub: string,
    domain: string,
    ext: string
  ): string {
    let baseUrl = `${protocol}://`;
    if (sub) { baseUrl += `${sub}.`; }
    baseUrl += `${domain}.${ext}`;
    if (port) { baseUrl += `:${port}`; }
    return baseUrl;
  }
}
