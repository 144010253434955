import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WoExternalComponent } from './work/wo-external/wo-external.component';
import { WoExLandingComponent } from './work/wo-external/wo-ex-landing/wo-ex-landing.component';

import { WoGroundComponent } from './work/wo-ground/wo-ground.component';
import { WoGrLandingComponent } from './work/wo-ground/wo-gr-landing/wo-gr-landing.component';
import { WoGrDsammComponent } from './work/wo-ground/wo-gr-dsamm/wo-gr-dsamm.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'work/external/landing' },
  {
    path: 'work/external', component: WoExternalComponent,
    children: [
      { path: 'landing', component: WoExLandingComponent},
    ],
  },
  {
    path: 'work/ground', component: WoGroundComponent,
    children: [
      { path: 'landing', component: WoGrLandingComponent},
      { path: 'dsamm', component: WoGrDsammComponent},
    ],
  },
  { path: '**', redirectTo: 'work/external/landing' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
