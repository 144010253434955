<app-ge-gr-profile></app-ge-gr-profile>
<main>
  <!-- section -->
  <section class="position-relative sectionLanding">
    <img src="{{ urlFront }}/assets/apps/images/ground/landing/closeup-developer.jpg"
    alt="Background Image" class="img-fluid background-image" />
    <div class="container my-lg-8 position-relative background-content">
      <div class="row align-items-center">
        <div class="col-12 mb-8"></div>
        <div class="col-lg-6 offset-lg-4 mb-6 mb-lg-0">
          <div class="text-center">
            <h1 class="display-3 fw-bold mb-3 text-dark">
              <span>Fundamentos de Construcción de Software</span>
            </h1>
            <h4 class="pe-lg-10 mb-5 text-dark">
              ¡Bienvenido al blog sobre desarrollo de software y metodologías ágiles!
              En la página principal, te ofrecemos una visión completa sobre la importancia
              de conocer diferentes modelos de construcción de software y cómo las metodologías
              ágiles pueden revolucionar tu enfoque en el desarrollo de software.
              Descubre cómo mejorar tus habilidades y mantenerte actualizado
              en un mundo tecnológico en constante evolución.
            </h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<main class="docs-wrapper">
  <section class="container-fluid">
    <div class="row">
      <div class="col-12 mb-2"></div>
      <!-- Informacion Pagina -->
      <div class="col-8 mb-2">
        <!-- Card Informacion -->
        <div class="card rounded-0 smooth-shadow-md mb-5 bg-white">
          <div class="card-body">
            <div class="tab-content" id="tabPurpose">
              <!-- Tab pane -->
              <div class="tab-pane fade active show" id="purpose" role="tabpanel" aria-labelledby="purpose-tab">
                <h3 class="mb-2">
                  <span>Propósito del Blog</span>
                </h3>
                <ul class="list-unstyled">
                  <li class="d-flex mb-2">
                    <span class="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-circle text-success" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                      </svg>
                    </span>
                    <span>
                      Descubre el Mundo del Desarrollo de Software y las Metodologías Ágiles
                    </span>
                  </li>
                  <li class="d-flex mb-2">
                    <span class="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-circle text-success" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                      </svg>
                    </span>
                    <span>
                      En nuestro blog, podrás explorar información valiosa sobre el desarrollo de software y las metodologías ágiles.
                    </span>
                  </li>
                  <li class="d-flex mb-2">
                    <span class="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-circle text-success" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                      </svg>
                    </span>
                    <span>
                      El propósito es brindarte una perspectiva detallada y práctica sobre cómo construir software de manera efectiva y eficiente.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Card Informacion -->
        <div class="card rounded-0 smooth-shadow-md mb-5 bg-white">
          <div class="card-body">
            <div class="tab-content" id="tabPurpose">
              <!-- Tab pane -->
              <div class="tab-pane fade active show" id="purpose" role="tabpanel" aria-labelledby="purpose-tab">
                <h3 class="mb-2">
                  <span>
                    Importancia de Conocer Diferentes Modelos de Construcción de Software y Metodologías Ágiles
                  </span>
                </h3>
                <ul class="list-unstyled">
                  <li class="d-flex mb-2">
                    <span class="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-circle text-success" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                      </svg>
                    </span>
                    <span>
                      Es fundamental comprender los diferentes modelos de construcción de software y el uso de metodologías ágiles en el desarrollo de software
                    </span>
                  </li>
                  <li class="d-flex mb-2">
                    <span class="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-circle text-success" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                      </svg>
                    </span>
                    <span>
                      Las prácticas y enfoques son clave para garantizar la calidad, la eficiencia y la flexibilidad en el desarrollo de software.
                    </span>
                  </li>
                  <li class="d-flex mb-2">
                    <span class="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-circle text-success" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                      </svg>
                    </span>
                    <span>
                      Al conocer y aplicar estos conceptos, los profesionales del desarrollo de software pueden mejorar la colaboración, la comunicación y la entrega de productos de software de alta calidad.
                    </span>
                  </li>
                  <li class="d-flex mb-2">
                    <span class="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-circle text-success" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                      </svg>
                    </span>
                    <span>
                      La adopción de metodologías ágiles puede ayudar a las empresas a adaptarse rápidamente a los cambios y a satisfacer las necesidades cambiantes de los clientes.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Post Publicados -->
      <div class="col-4 mb-2 mx-auto">
        <!-- Card -->
        <div class="card rounded-0 smooth-shadow-md mb-5 bg-white">
          <a href="work/ground/dsamm" title="Actividades, Modelos y Metodologias del Desarrollo de Software">
            <img src="{{ urlFront }}/assets/apps/images/ground/landing/standard-quality-control-concept.jpg" class="card-img-top rounded-0" alt="">
          </a>
          <!-- Card Body -->
          <div class="card-body">
            <a href="work/ground/dsamm" class="badge bg-info mb-3 rounded-0">
              <span>Publicacón</span>
            </a>
            <h3>
              <a href="work/ground/dsamm" class="text-inherit">
                <span>Actividades, Modelos y Metodologias del Desarrollo de Software</span>
              </a>
            </h3>
            <p>
              Descubre las actividades, modelos y metodologías del
              desarrollo de software que te ayudarán a planificar,
              diseñar, implementar y mantener sistemas eficientes y de calidad.
              Aprende cómo aplicar estas prácticas para mejorar tus
              proyectos y alcanzar tus objetivos de desarrollo de software.
            </p>
            <!-- Media Content -->
            <div class="row align-items-center g-0 mt-4">
              <div class="col-auto">
                <img src="{{ urlFront }}/assets/apps/images/ground/profile/adison_jimenez.png" alt="" class="rounded-circle avatar-md me-2">
              </div>
              <div class="col lh-1">
                <h5 class="mb-1">Adison Jimenez</h5>
                <p class="fs-6 mb-0">Marzo 01, 2024</p>
              </div>
              <div class="col-auto">
                <p class="fs-6 mb-0 d-none">20 Min Read</p>
                <a href="work/ground/dsamm" class="badge bg-success mb-3 rounded-0">
                  <span>Leer Más</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--<div class="offset-xxl-2 col-xxl-6 offset-xl-1 col-xl-7 col-md-12 col-sm-12 col-12 d-none">
        <div class="docs-content mx-xxl-9">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="mb-8" id="intro">
                        <h1 class="mb-2 fw-bold display-3">Introduction</h1>
                        <p class="mb-6 lead">
                            Geeks UI is beautifully designed, expertly crafted components UI kit for building a high-quality website and web apps using web technologies — HTML,
                            CSS, and JavaScript — with integrations of the world’s most popular framework
                            <a href="https://getbootstrap.com/" target="_blank" rel="nofollow">Bootstrap</a>
                            .
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="mb-8">
                        <p class="fs-4 mb-0">Guide will help you get started with a Geeks Theme, including how to run, customize, update, and integrate your theme!</p>
                        <div class="my-6">
                            <div class="alert alert-info" role="alert">
                                Note: We highly recommend to check out The Guide an official Bootstrap Theme
                                <a href="https://themes.getbootstrap.com/guide/" class="alert-link">guidelines</a>
                                by Bootstrap core team.
                            </div>
                        </div>
                    </div>
                    <div class="mb-8" id="setting-up">
                        <h2 class="fw-semibold">Setting up local development</h2>
                        <p class="mb-0">
                            Geeks is built using modern development toolset. Setting up local development Like Gulp, Node js, SASS & Bootstrap SCSS.
                            <a href="environment-setup.html">Read More</a>
                        </p>
                    </div>
                    <div class="mb-8" id="customize-theme">
                        <div class="mb-4">
                            <h2 class="fw-semibold">Customize your theme</h2>
                            <p class="mb-0">
                                Now that you’ve got your theme running and understand the compiling process, let’s discuss the proper ways to edit and customize your theme. There
                                are two primary strategies for working with your theme.
                            </p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-12">
                                <div class="card border shadow-none mb-4 mb-lg-0">
                                    <div class="card-body">
                                        <div class="mb-4">
                                            <img src="{{ urlFront }}/assets/geeks/images/path/path-gulp.svg" alt="" class="avatar-lg">
                                        </div>
                                        <h3 class="fw-semibold">Working with Gulp</h3>
                                        <p>Working with source files showcases how powerful Bootstrap themes can be and the underlying design systems.</p>
                                        <a href="#" class="btn-link">Start with Gulp</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-12">
                                <div class="card border shadow-none mb-4 mb-lg-0">
                                    <div class="card-body">
                                        <div class="mb-4">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="54"
                                                height="54"
                                                fill="currentColor"
                                                class="bi bi-folder-fill text-warning"
                                                viewBox="0 0 16 16">
                                                <path
                                                    d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"></path>
                                            </svg>
                                        </div>
                                        <h3 class="fw-semibold">Working with compiled files</h3>
                                        <p>Simply attach the compiled CSS and JS files to an HTML page, or use an HTML page already provided in your theme.</p>
                                        <a href="#" class="btn-link">Start with compiled HTML files</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>-->
      <!-- Sidebar nav fixed -->
      <!--<div class="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12 d-none d-xl-block position-fixed end-0">
          <div class="sidebar-nav-fixed">
              <span class="px-4 mb-2 d-block text-uppercase ls-md fw-semibold fs-6">Contents</span>
              <ul class="list-unstyled">
                  <li><a href="#intro" class="active">Introduction</a></li>
                  <li><a href="#setting-up">Setting Up</a></li>
                  <li><a href="#customize-theme">Customize Theme</a></li>
              </ul>
          </div>
      </div>-->
    </div>
  </section>
  <app-ge-gr-footer></app-ge-gr-footer>
</main>
