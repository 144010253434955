<aside class="docs-nav-sidebar">
  <div class="py-5"></div>
  <div class="docs-nav" data-simplebar>
    <nav class="navbar navbar-expand-md">
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav flex-column" id="sidebarnav">
          <li class="navbar-header">
            <h5 class="heading">Getting Started</h5>
          </li>
          <li class="nav-item"><a href="index.html" class="nav-link">Introduction</a></li>
          <li class="nav-item"><a href="environment-setup.html" class="nav-link">Environment setup</a></li>
          <li class="nav-item"><a href="working-with-gulp.html" class="nav-link">Working with Gulp</a></li>
          <li class="nav-item"><a href="compiled-files.html" class="nav-link">Compiled Files</a></li>
          <li class="nav-item"><a href="file-structure.html" class="nav-link">File Structure</a></li>
          <li class="nav-item"><a href="resources-assets.html" class="nav-link">Resources & assets</a></li>
          <li class="nav-item"><a href="changelog.html" class="nav-link">Changelog</a></li>

          <li>
              <div class="navbar-border"></div>
          </li>
          <li class="navbar-header mt-0">
              <h5 class="heading">Foundation</h5>
          </li>
          <li class="nav-item"><a href="typography.html" class="nav-link">Typography</a></li>
          <li class="nav-item"><a href="colors.html" class="nav-link">Colors</a></li>
          <li class="nav-item"><a href="shadows.html" class="nav-link">Shadows</a></li>

          <li>
              <div class="navbar-border"></div>
          </li>

          <li class="navbar-header mt-0">
              <h5 class="heading">Snippet</h5>
          </li>
          <li class="nav-item">
              <a href="bootstrap-5-snippets.html" class="nav-link">Introduction</a>
          </li>
          <li class="nav-item">
              <a href="accordions-snippet.html" class="nav-link">Accordions</a>
          </li>
          <li class="nav-item">
              <a href="card-snippet.html" class="nav-link">Card</a>
          </li>
          <li class="nav-item">
              <a href="chart.html" class="nav-link">Chart</a>
          </li>
          <li class="nav-item">
              <a href="client-snippet.html" class="nav-link">Clients</a>
          </li>
          <li class="nav-item">
              <a href="cta-snippet.html" class="nav-link">CTA</a>
          </li>
          <li class="nav-item">
              <a href="features-snippet.html" class="nav-link">Features</a>
          </li>
          <li class="nav-item">
              <a href="footer-snippet.html" class="nav-link">Footer</a>
          </li>
          <li class="nav-item">
              <a href="header-snippet.html" class="nav-link">Headers</a>
          </li>
          <li class="nav-item">
              <a href="hero-snippet.html" class="nav-link">Hero</a>
          </li>
          <li class="nav-item">
              <a href="image-snippet.html" class="nav-link">Image</a>
          </li>
          <li class="nav-item">
              <a href="integration-snippet.html" class="nav-link">Integrations</a>
          </li>
          <li class="nav-item">
              <a href="pricing.html" class="nav-link">Pricing</a>
          </li>
          <li class="nav-item">
              <a href="reviews.html" class="nav-link">Reviews</a>
          </li>
          <li class="nav-item">
              <a href="slider-snippet.html" class="nav-link">Slider</a>
          </li>
          <li class="nav-item">
              <a href="stats.html" class="nav-link">Stats</a>
          </li>
          <li class="nav-item">
              <a href="tables-snippet.html" class="nav-link">Tables</a>
          </li>
          <li class="nav-item">
              <a href="team-snippet.html" class="nav-link">Team</a>
          </li>
          <li class="nav-item">
              <a href="testimonials-snippet.html" class="nav-link">Testimonials</a>
          </li>
          <li class="nav-item">
              <a href="user-profile.html" class="nav-link">User Profile</a>
          </li>
          <li>
              <div class="navbar-border"></div>
          </li>
          <li class="navbar-header mt-0">
              <h5 class="heading">Components</h5>
          </li>
          <li class="nav-item">
              <a href="accordions.html" class="nav-link">Accordions</a>
          </li>
          <li class="nav-item">
              <a href="alerts.html" class="nav-link">Alerts</a>
          </li>
          <li class="nav-item">
              <a href="avatar.html" class="nav-link">Avatar</a>
          </li>
          <li class="nav-item">
              <a href="badge.html" class="nav-link">Badge</a>
          </li>

          <li class="nav-item">
              <a href="breadcrumb.html" class="nav-link">Breadcrumb</a>
          </li>
          <li class="nav-item">
              <a href="buttons.html" class="nav-link">Buttons</a>
          </li>
          <li class="nav-item">
              <a href="button-group.html" class="nav-link">Button group</a>
          </li>
          <li class="nav-item">
              <a href="card.html" class="nav-link">Card</a>
          </li>
          <li class="nav-item">
              <a href="carousel.html" class="nav-link">Carousel</a>
          </li>
          <li class="nav-item">
              <a href="close-button.html" class="nav-link">Close Button</a>
          </li>
          <li class="nav-item">
              <a href="collapse.html" class="nav-link">Collapse</a>
          </li>
          <li class="nav-item">
              <a href="dropdowns.html" class="nav-link">Dropdowns</a>
          </li>
          <li class="nav-item">
              <a href="images.html" class="nav-link">Images</a>
          </li>
          <li class="nav-item">
              <a href="list-group.html" class="nav-link">List group</a>
          </li>

          <li class="nav-item">
              <a href="modal.html" class="nav-link">Modal</a>
          </li>
          <li class="nav-item">
              <a href="navs-tabs.html" class="nav-link">Navs and tabs</a>
          </li>
          <li class="nav-item">
              <a href="navbar.html" class="nav-link">Navbar</a>
          </li>
          <li class="nav-item">
              <a href="offcanvas.html" class="nav-link">Offcanvas</a>
          </li>
          <li class="nav-item">
              <a href="pagination.html" class="nav-link">Pagination</a>
          </li>
          <li class="nav-item">
              <a href="placeholders.html" class="nav-link">Placeholders</a>
          </li>
          <li class="nav-item">
              <a href="popovers.html" class="nav-link">Popovers</a>
          </li>
          <li class="nav-item">
              <a href="progress.html" class="nav-link">Progress</a>
          </li>
          <li class="nav-item">
              <a href="scrollspy.html" class="nav-link">Scrollspy</a>
          </li>
          <li class="nav-item">
              <a href="spinners.html" class="nav-link">Spinners</a>
          </li>
          <li class="nav-item">
              <a href="tables.html" class="nav-link">Tables</a>
          </li>
          <li class="nav-item">
              <a href="toasts.html" class="nav-link">Toasts</a>
          </li>
          <li class="nav-item">
              <a href="tooltips.html" class="nav-link">Tooltips</a>
          </li>
          <li>
              <div class="navbar-border"></div>
          </li>
          <li class="navbar-header mt-0">
              <h5 class="heading">Forms</h5>
          </li>
          <li class="nav-item">
              <a href="forms.html" class="nav-link">Basic Forms</a>
          </li>
          <li class="nav-item">
              <a href="advance-forms.html" class="nav-link">Advance Forms</a>
          </li>

          <li class="nav-item">
              <a href="dropzone.html" class="nav-link">Dropzone</a>
          </li>
          <li class="nav-item">
              <a href="flatpickr.html" class="nav-link">Datepicker</a>
          </li>
          <li class="nav-item">
              <a href="input-group.html" class="nav-link">Input Group</a>
          </li>
          <li class="nav-item">
              <a href="inputmask.html" class="nav-link">Imask</a>
          </li>
          <li class="nav-item">
              <a href="quill-editor.html" class="nav-link">Quill Editor</a>
          </li>
          <li class="nav-item">
              <a href="tagify.html" class="nav-link">Tagify</a>
          </li>

          <li>
              <div class="navbar-border"></div>
          </li>
          <li class="navbar-header mt-0">
              <h5 class="heading">Utilities</h5>
          </li>
          <li class="nav-item"><a href="background.html" class="nav-link">Background</a></li>
          <li class="nav-item"><a href="borders.html" class="nav-link">Borders</a></li>
          <li class="nav-item"><a href="colored-links.html" class="nav-link">Colored Links</a></li>
          <li class="nav-item"><a href="opacity.html" class="nav-link">Opacity</a></li>
          <li class="nav-item"><a href="ratio.html" class="nav-link">Ratio</a></li>
          <li class="nav-item"><a href="stacks.html" class="nav-link">Stacks</a></li>
          <li class="nav-item"><a href="text-color.html" class="nav-link">Colors</a></li>
          <li class="nav-item"><a href="text.html" class="nav-link">Text</a></li>
          <li class="nav-item"><a href="text-truncation.html" class="nav-link">Text truncation</a></li>
          <li class="nav-item"><a href="vertical-rule.html" class="nav-link">Vertical rule</a></li>
        </ul>
      </div>
    </nav>
  </div>
  <div class="nav-footer">
    <p class="mb-0">
      <span>Desarollado Por</span>
    </p>
    <p class="mb-0">
      <a href="{{ domainMajor }}" target="_blank">
        <span>Adison Jimenez</span>
      </a>
    </p>
  </div>
</aside>
