import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { GeExFooterComponent } from './geeks/external/ge-ex-footer/ge-ex-footer.component';
import { GeExNavbarComponent } from './geeks/external/ge-ex-navbar/ge-ex-navbar.component';

import { GeInNavbarComponent } from './geeks/internal/ge-in-navbar/ge-in-navbar.component';
import { GeInFooterComponent } from './geeks/internal/ge-in-footer/ge-in-footer.component';

import { GeGrNavbarComponent } from './geeks/ground/ge-gr-navbar/ge-gr-navbar.component';
import { GeGrFooterComponent } from './geeks/ground/ge-gr-footer/ge-gr-footer.component';
import { GeGrAsideComponent } from './geeks/ground/ge-gr-aside/ge-gr-aside.component';
import { GeGrProfileComponent } from './geeks/ground/ge-gr-profile/ge-gr-profile.component';

import { WoExternalComponent } from './work/wo-external/wo-external.component';
import { WoExLandingComponent } from './work/wo-external/wo-ex-landing/wo-ex-landing.component';

import { WoGroundComponent } from './work/wo-ground/wo-ground.component';
import { WoGrDsammComponent } from './work/wo-ground/wo-gr-dsamm/wo-gr-dsamm.component';
import { WoGrLandingComponent } from './work/wo-ground/wo-gr-landing/wo-gr-landing.component';

@NgModule({
  declarations: [
    AppComponent,

    GeExFooterComponent,
    GeExNavbarComponent,

    GeInNavbarComponent,
    GeInFooterComponent,

    GeGrNavbarComponent,
    GeGrFooterComponent,
    GeGrAsideComponent,
    GeGrProfileComponent,

    WoExternalComponent,
    WoExLandingComponent,

    WoGroundComponent,
    WoGrDsammComponent,
    WoGrLandingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
